<template>
  <div class="container-fluid mv-table-container mt-2 mb-4">
    <table class="table table-bordered mv-table">
      <thead class="mv-table__head">
        <tr class="mv-table__row">
          <th scope="col">Name</th>
          <th scope="col" v-show="showOrganization === true">Organization</th>
          <th scope="col" v-show="showBenefit === true">Benefit</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody class="mv-table__body" id="mv-table-content">
      <tr v-for="(entry, i) in records" :key="i"  class="mv-table__row">
        <td class="mv-table__cell">
          <!-- eslint-disable-next-line -->
          <label class="visually-hidden" :for="('name' + i)">Name:</label>
          <input
            v-model="records[i].name"
            @blur="updateRecord(listIndex, i, 'name', records[i].name);"
            placeholder="Name..."
            class="mv-table__input"
            type="text"
            :id="('name' + i)"
            :name="('name' + i)">
        </td>
        <td class="mv-table__cell" v-show="showOrganization === true">
          <!-- eslint-disable-next-line -->
          <label class="visually-hidden" :for="('organization' + i)">Organization:</label>
          <input
            v-model="records[i].organization"
            @blur="updateRecord(listIndex, i, 'organization', records[i].organization);"
            placeholder="Organization..."
            class="mv-table__input"
            type="text"
            :id="('organization' + i)"
            :name="('organization' + i)">
        </td>
        <td class="mv-table__cell" v-show="showBenefit === true">
          <!-- eslint-disable-next-line -->
          <label class="visually-hidden" :for="('benefit' + i)">Benefit:</label>
          <input
            v-model="records[i].benefit"
            @blur="updateRecord(listIndex, i, 'benefit', records[i].benefit);"
            placeholder="Benefit..."
            class="mv-table__input"
            type="text"
            :id="('benefit' + i)"
            :name="('benefit' + i)">
        </td>
        <td class="mv-table__cell d-flex align-items-center justify-content-end">
          <button class="btn btn-outline mv-icon-btn" aria-label="Delete Partner" @click="deleteRecord(listIndex, i);">
            <i class="bi bi-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-end">
      <button class="btn btn-sm btn-primary" @click="addRecord(listIndex)">+ Add Row</button>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'GetReadyPeopleList',
  props: {
    listIndex: {
      type: String,
      required: true,
      default: '',
    },
    showOrganization: {
      type: Boolean,
      required: false,
      default: true,
    },
    showBenefit: {
      type: Boolean,
      required: false,
      default: true,
    },
    partners: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();

    return {
      records: computed(() => {
        if (props.partners) {
          return store.state.partners.partners;
        } else {
          return store.state.readinessAssessment.peopleList[props.listIndex];
        }
      }),
      addRecord: (type) => {
        if (props.partners) {
          store.dispatch('partners/addPartner');
        } else {
          store.dispatch('readinessAssessment/addPerson', { type });
        }
      },
      deleteRecord: (type, id) => {
        const c = confirm('Are you sure you want to delete this record?');
        if (c === true) {
          if (props.partners) {
            store.dispatch('partners/deletePartner', { id });
          } else {
            store.dispatch('readinessAssessment/deletePerson', { type, id });
          }
        }
      },
      updateRecord: (type, id, prop, value) => {
        if (props.partners) {
          store.dispatch('readinessAssessment/updatePartner', { id, prop, value });
        } else {
          store.dispatch('readinessAssessment/updatePerson', { type, id, prop, value });
        }
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.mv-table {
  border-color: transparent;
  font-size: $form-table-font-size;
  &__body {
    padding-top: 1rem;
  }
  &__head,
  thead {
    border-bottom: $form-table-border;
  }
  th {
    color: $form-table-label-text-color;
  }
  &__row {
    border-color: transparent;
  }
  &__cell,
  td {
    padding: $form-table-cell-padding;
  }
  &__input {
    background: $form-table-input-bg;
    border:2px solid $primary;
    border-radius:3px;
    color: $mv_secondary_blue;
    font-size:14px;
    height:100%;
    padding:4px 2px;
    transition: $transition-default;
    width: $form-table-input-width;
    &:focus,
    &:focus-visible {
      border-color: $info;
      outline:none;
    }
  }
}
.mv-icon-btn {
  padding: 1px 4px;
  i {
    font-size: 1.1rem;
  }
}
.btn-info,
.btn-secondary,
.btn-success,
.btn-danger {
  &,
  &:link,
  &:visited,
  &:active,
  &:hover {
    color: $light;
  }
}
</style>
