<template>
  <header id="page-header">
    <h1 class="h1">Get Ready</h1>
  </header>
  <div class="container py-5">
    <h2 class="h2">Community Readiness Checklist</h2>
    <p class="p">This checklist will help:</p>
    <ul class="mv-list">
      <li class="mv-list__item">Identify which Mosaic Village components are already in place in your community, and which will need to be developed</li>
      <li class="mv-list__item">Prioritize the next steps</li>
      <li class="mv-list__item">Inform the action planning process</li>
    </ul>
    <p class="p">This checklist does not need to be completed in one sitting. Your work and progress will automatically be saved.</p>
    <hr class="bg-default border-2 border-top border-default mt-5 mb-5" aria-hidden="true">
    <div class="row mt-3 mb-3">
      <div class="col-10">
        <div v-if="currentQuestion === 1">
          <!-- Question 1 -->
          <h3 class="h3">We have a core group of partners, including youth leaders, who are committed to measurably reducing youth homelessness in our community.</h3>
          <form>
            <div class="row my-4">
              <div class="col">
                <label class="form-check-label" for="question1_yes">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="yes"
                    id="question1_yes"
                    v-model="questionAnswers[1]"
                    @click="answerQuestion({questionId: 1, answer: 'yes'});">
                  Yes
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question1_workingonit">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="notyet"
                    id="question1_workingonit"
                    v-model="questionAnswers[1]"
                    @click="answerQuestion({questionId: 1, answer: 'notyet'});">
                  Not yet, but we're working on it
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question1_no">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="no"
                    id="question1_no"
                    v-model="questionAnswers[1]"
                    @click="answerQuestion({questionId: 1, answer: 'no'});">
                  No
                </label>
              </div>
            </div>
          </form>
          <hr class="bg-default border-2 border-top border-default" aria-hidden="true">
          <div v-if="questionAnswers[1] === 'yes'">
            <div class="alert alert-light">
              <p class="p">Great! You have the most important thing you'll need to get started with Mosaic Village.</p>
              <p class="form-label mv-input-group__label">List those partners and their organizations/affiliations below.</p>
              <GetReadyPeopleList listIndex="q1yes" partners=true showBenefit=false></GetReadyPeopleList>
            </div>

          </div>
          <div v-else-if="questionAnswers[1] === 'notyet'">
            <div class="alert alert-light">
              <p class="p">You're on your way.</p>
              <p class="form-label mv-input-group__label">Who has already expressed an interest? Who else should be involved?</p>
              <GetReadyPeopleList listIndex="q1yes" partners=true showBenefit=false></GetReadyPeopleList>
            </div>
          </div>
          <div v-else-if="questionAnswers[1] === 'no'">
            <div class="alert alert-light">
              <p class="p">OK. A good place to start is thinking about who has the knowledge and/or influence to contribute to reducing youth homelessness.</p>
              <p class="form-label mv-input-group__label">Make a wish list of people or organizations who should be engaged in this effort.</p>
              <GetReadyPeopleList listIndex="q1yes" partners=true showBenefit=false></GetReadyPeopleList>
            </div>

          </div>
        </div>
        <div v-else-if="currentQuestion === 2">
          <!-- Question 2 -->
          <h3 class="h3">We have identified an influential champion who can bring cross-sector leaders and unhoused youth together and begin a collaborative planning process.</h3>
          <form>
            <div class="row my-4">
              <div class="col">
                <label class="form-check-label" for="question2_yes">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="yes"
                    id="question2_yes"
                    v-model="questionAnswers[2]"
                    @click="answerQuestion({questionId: 2, answer: 'yes'});">
                  Yes
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question2_workingonit">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="working"
                    id="question2_workingonit"
                    v-model="questionAnswers[2]"
                    @click="answerQuestion({questionId: 2, answer: 'working'});">
                  Not yet, but we're working on it
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question2_no">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="no"
                    id="question2_no"
                    v-model="questionAnswers[2]"
                    @click="answerQuestion({questionId: 2, answer: 'no'});">
                  No
                </label>
              </div>
            </div>
          </form>
          <hr class="bg-default border-2 border-top border-default" aria-hidden="true">
          <div v-if="questionAnswers[2] === 'yes'">
            <div class="alert alert-light"><p class="p"></p>
            <!-- eslint-disable-next-line -->
              <label class="form-label mv-input-group__label" for="question2Textarea">Who? What makes them effective?</label>
              <textarea
                v-model="question2Textarea"
                class="form-control form-control-sm mv-input-group__input"
                id="question2Textarea">
              </textarea>
            </div>
          </div>
          <div v-else-if="questionAnswers[2] === 'working' || questionAnswers[2] === 'no'">
            <div class="alert alert-light">
              <p class="p">Getting your champion on board will open many doors.</p>
              <h6 class="h6 fw-bold">Who is your community champion?</h6>
              <p class="p">
                A community champion is a high-profile member of the community who can bring awareness about, pique interest in, and open doors for your initiative.
              </p>
              <p class="p">
                The community champion isn’t the hands-on day-to-day administrator—that is the community coordinator. The two, in partnership, build the profile, relationships and infrastructure necessary to launch and sustain the initiative. The champion opens doors, and the coordinator walks through them.
              </p>
              <div class="mv-illustration d-flex align-items-center justify-content-center w-100">
                <img class="mv-illustration__img" src="../../public/img/misc/final-champion.gif" alt="Final champion image">
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="currentQuestion === 3">
          <!-- Question 3 -->
          <h3 class="h3">Our community feels urgency about addressing the issue of youth homelessness in new and different ways. Multiple folks, including policy makers and funders, have expressed frustration with the current situation.</h3>
          <form>
            <div class="row my-4">
              <div class="col">
                <label class="form-check-label" for="question3_yes">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="yes"
                    id="question3_yes"
                    v-model="questionAnswers[3]"
                    @click="answerQuestion({questionId: 3, answer: 'yes'});">
                  Yes
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question3_notyet">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="notyet"
                    id="question3_notyet"
                    v-model="questionAnswers[3]"
                    @click="answerQuestion({questionId: 3, answer: 'notyet'});">
                  Not yet, but it's getting there
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question3_no">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="no"
                    id="question3_no"
                    v-model="questionAnswers[3]"
                    @click="answerQuestion({questionId: 3, answer: 'no'});">
                  No
                </label>
              </div>
            </div>
          </form>
          <hr class="bg-default border-2 border-top border-default" aria-hidden="true">
          <div v-if="questionAnswers[3] === 'yes'">
            <div class="alert alert-light">
              Who has been talking about this? How might they contribute to the initiative?
              <GetReadyPeopleList listIndex="q3yes" partners=true></GetReadyPeopleList>
            </div>
          </div>
          <div v-else-if="questionAnswers[3] === 'notyet' || questionAnswers[3] === 'no'">
            <div class="alert alert-light">

              <!-- eslint-disable-next-line -->
              <label class="form-label mv-input-group__label" for="question3Textarea">Who might be interested in learning more about youth homelessness in our community? Who might help connect me with those community leaders?</label>
              <textarea
                v-model="question3Textarea"
                class="form-control form-control-sm mv-input-group__input"
                id="question3Textarea">
              </textarea>
            </div>
          </div>
        </div>
        <div v-else-if="currentQuestion === 4">
          <!-- Question 4 -->
          <h3 class="h3">We will ensure that youth have a meaningful role in the leadership of this initiative.</h3>
          <form>
            <div class="row my-4">
              <div class="col">
                <label class="form-check-label" for="question4_yes">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="yes"
                    id="question4_yes"
                    v-model="questionAnswers[4]"
                    @click="answerQuestion({questionId: 4, answer: 'yes'});">
                  Yes
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question4_notyet">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="notyet"
                    id="question4_notyet"
                    v-model="questionAnswers[4]"
                    @click="answerQuestion({questionId: 4, answer: 'notyet'});">
                  Not yet, but we're considering it
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question4_no">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="no"
                    id="question4_no"
                    v-model="questionAnswers[4]"
                    @click="answerQuestion({questionId: 4, answer: 'no'});">
                  No
                </label>
              </div>
            </div>
          </form>
          <hr class="bg-default border-2 border-top border-default" aria-hidden="true">
          <div v-if="questionAnswers[4] === 'yes'">
            <div class="alert alert-light">
              <p class="p">Excellent. Active youth leadership is critical to the equity and effectiveness of your initiative.</p>
            </div>
          </div>
          <div v-else-if="questionAnswers[4] === 'notyet' || questionAnswers[4] === 'no'">
            <div class="alert alert-light">
              <h6 class="h6 fw-bold">Why youth should have an active leadership role</h6>
              <p class="p">Youth have an active role in Mosaic Village because they contribute insights, lived experience, and an authentic voice</p>
              <p class="p">When you <span class="fst-italic">design</span> for young people, you’re making assumptions—guessing even—about how your project will be received. When you <span class="fst-italic">design with</span> them, the combination of your professional expertise and their unique perspective creates high quality, relevant programs.</p>
            </div>
          </div>
        </div>
        <div v-else-if="currentQuestion === 5">
          <!-- Question 5 -->
          <h3 class="h3">We have engaged unhoused youth in discussions about their experiences.</h3>
          <form>
            <div class="row my-4">
              <div class="col">
                <label class="form-check-label" for="question5_yes">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="yes"
                    id="question5_yes"
                    v-model="questionAnswers[5]"
                    @click="answerQuestion({questionId: 5, answer: 'yes'});">
                  Yes
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question5_notyet">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="notyet"
                    id="question5_notyet"
                    v-model="questionAnswers[5]"
                    @click="answerQuestion({questionId: 5, answer: 'notyet'});">
                  Not yet, but we're considering it
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question5_no">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="no"
                    id="question5_no"
                    v-model="questionAnswers[5]"
                    @click="answerQuestion({questionId: 5, answer: 'no'});">
                  No
                </label>
              </div>
            </div>
          </form>
          <hr class="bg-default border-2 border-top border-default" aria-hidden="true">
          <div v-if="questionAnswers[5] === 'yes'">
            <div class="alert alert-light">
              <!-- eslint-disable-next-line -->
              <label class="form-label mv-input-group__label" for="question5Textarea">How have they been engaged? Is there an existing mechanism to facilitate ongoing involvement?</label>
              <textarea
                v-model="question5Textarea"
                class="form-control form-control-sm mv-input-group__input"
                id="question5Textarea">
              </textarea>
            </div>
          </div>
          <div v-else-if="questionAnswers[5] === 'notyet' || questionAnswers[5] === 'no'">
            <div class="alert alert-light">
              <h6 class="h6 fw-bold">What is a Youth Action Council and how do I get one?</h6>
              <p class="p">At the heart of Mosaic Village’s approach is your community’s Youth Action Council (YAC).</p>
              <p class="p">A YAC is made up of young people—many with lived experience with housing insecurity—who are committed to creating better outcomes for youth in their community. They meet kids where they are, connect with them, and get them connected to the services they need to stay in school and off the streets.</p>
              <p class="p">The YAC helps shape the direction of your Mosaic Village initiative. They are at the table for important conversations, making sure that youth perspectives are considered from the start. They help research, design and create community programming and resources.</p>
              <p class="p">YAC members also help partners better understand how unhoused youth experience interactions with their organizations, and how they might improve that experience.</p>
              <p class="p">
                <span class="fw-bold">Getting your YAC up and running takes three main ingredients:</span>
                <ol>
                  <li>
                    <h6 class="h6">Backseat Facilitation</h6>
                    <p class="p">Recruit a YAC Facilitator whose role is to enable youth to take the lead. On the “Start Youth Action Council” step of the Mosaic Village platform, you’ll find a sample job description that outlines the experience and qualities that prepare a person to succeed in this role. One of the most important is the ability to provide support, infrastructure and connections for the YAC, and then step back and put them in the driver’s seat.</p>
                  </li>
                  <li>
                    <h6 class="h6">Member Recruitment</h6>
                    <p class="p">Outreach is broken into two types: Open calls to participate posted in places youth gather, and individual invitations from the YAC facilitator as well as staff and volunteers at youth-serving organizations. Personal outreach tends to be more successful in getting prospects to actually follow up and attend their first meeting.</p>
                  </li>
                  <li>
                    <h6 class="h6">Member Stipends</h6>
                    <p class="p">Paying youth a stipend for their YAC service shows respect and demonstrates that their work is a critical component of your initiative’s success.</p>
                  </li>
                </ol>
              </p>
            </div>
          </div>
        </div>
        <div v-else-if="currentQuestion === 6">
          <h3 class="h3">Stakeholders have existing relationships that will enable engaging a broad, cross-sector group to lead this initiative.</h3>
          <form>
            <div class="row my-4">
              <div class="col">
                <label class="form-check-label" for="question6_yes">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="yes"
                    id="question6_yes"
                    v-model="questionAnswers[6]"
                    @click="answerQuestion({questionId: 6, answer: 'yes'});">
                  Yes
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question6_notyet">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="notyet"
                    id="question6_notyet"
                    v-model="questionAnswers[6]"
                    @click="answerQuestion({questionId: 6, answer: 'notyet'});">
                  Not yet, but we're considering it
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question6_no">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="no"
                    id="question6_no"
                    v-model="questionAnswers[6]"
                    @click="answerQuestion({questionId: 6, answer: 'no'});">
                  No
                </label>
              </div>
            </div>
          </form>
          <hr class="bg-default border-2 border-top border-default" aria-hidden="true">
          <div v-if="questionAnswers[6] === 'yes'">
            <!-- Question 7 -->
            <div class="alert alert-light">
              <h6 class="h6 fw-bold">How to use consultations to build connections and gather support</h6>
              <p class="p">
                One of the most important and rewarding parts of building your Mosaic Village initiative is reaching out to and consulting with people in your community to:
                <ul>
                  <li>Better understand their varying needs, interests and perceptions.</li>
                  <li>Identify people who will champion your initiative and serve as key team members.</li>
                  <li>Recruit partners and develop allies across organizations and sectors.</li>
                </ul>
              </p>
              <p class="p">You’ll have plenty of help. The “gather support” step of the Mosaic Village platform has tools to help you initiate, organize and manage your outreach plan.</p>
            </div>
          </div>
          <div v-else-if="questionAnswers[6] === 'notyet'|| questionAnswers[6] === 'no'">
            <div class="alert alert-light">
              <h6 class="h6 fw-bold">How to use consultations to build connections and gather support</h6>
              <p class="p">
                One of the most important and rewarding parts of building your Mosaic Village initiative is reaching out to and consulting with people in your community to:
                <ul>
                  <li>Better understand their varying needs, interests and perceptions.</li>
                  <li>Identify people who will champion your initiative and serve as key team members.</li>
                  <li>Recruit partners and develop allies across organizations and sectors.</li>
                </ul>
              </p>
              <p class="p">You’ll have plenty of help. The “gather support” step of the Mosaic Village platform has tools to help you initiate, organize and manage your outreach plan.</p>
            </div>
          </div>
        </div>
        <div v-else-if="currentQuestion === 7">
          <!-- Question 7 -->
          <h3 class="h3">Stakeholders have committed to using data to set the agenda and improve our work over time.</h3>
          <form>
            <div class="row my-4">
              <div class="col">
                <label class="form-check-label" for="question7_yes">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="yes"
                    id="question7_yes"
                    v-model="questionAnswers[7]"
                    @click="answerQuestion({questionId: 7, answer: 'yes'});">
                  Yes
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question7_notyet">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="notyet"
                    id="question7_notyet"
                    v-model="questionAnswers[7]"
                    @click="answerQuestion({questionId: 7, answer: 'notyet'});">
                  Not yet, but we're working on it
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question7_no">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="no"
                    id="question7_no"
                    v-model="questionAnswers[7]"
                    @click="answerQuestion({questionId: 7, answer: 'no'});">
                  No
                </label>
              </div>
            </div>
          </form>
          <hr class="bg-default border-2 border-top border-default" aria-hidden="true">
          <div v-if="questionAnswers[7] === 'yes'">
            <div class="alert alert-light">
              <span class="form-label mv-input-group__label">Have we identified data sources? Which measures seem most important?</span>
              <MeasureSourceList></MeasureSourceList>
            </div>
          </div>
          <div v-else-if="questionAnswers[7] === 'notyet'">
            <div class="alert alert-light">
              <!-- eslint-disable-next-line -->
              <label class="form-label mv-input-group__label" for="question7Textarea">What is standing in the way of a commitment to using shared data?</label>
              <textarea
                v-model="question7Textarea"
                class="form-control form-control-sm mv-input-group__input"
                id="question7Textarea">
              </textarea>
            </div>
          </div>
          <div v-else-if=" questionAnswers[7] === 'no'">
            <div class="alert alert-light">
              <h6 class="h6 fw-bold">What is shared measurement and why does it matter?</h6>
              <p class="p">Shared measurement is a key component of successful collective impact initiatives because agreeing on <span class="fw-bold">what</span> specifically will be measured and <span class="fw-bold">how</span> it will be measured keeps everyone on the same page.</p>
              <p class="p">The “develop common agenda and shared measurement” step will help your team build your shared measurement system so you can track progress toward your goals.</p>
            </div>
          </div>
        </div>
        <div v-else-if="currentQuestion === 8">
          <!-- Question 8 -->
          <h3 class="h3">We have an existing backbone support structure, or have identified an organization that could effectively take on this role.</h3>
          <form>
            <div class="row my-4">
              <div class="col">
                <label class="form-check-label" for="question8_yes">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="yes"
                    id="question8_yes"
                    v-model="questionAnswers[8]"
                    @click="answerQuestion({questionId: 8, answer: 'yes'});">
                  Yes
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question8_notyet">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="notyet"
                    id="question8_notyet"
                    v-model="questionAnswers[8]"
                    @click="answerQuestion({questionId: 8, answer: 'notyet'});">
                  Not yet, but we're working on it
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question8_no">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="no"
                    id="question8_no"
                    v-model="questionAnswers[8]"
                    @click="answerQuestion({questionId: 8, answer: 'no'});">
                  No
                </label>
              </div>
            </div>
          </form>
          <hr class="bg-default border-2 border-top border-default" aria-hidden="true">
          <div v-if="questionAnswers[8] === 'yes'">
            <div class="alert alert-light">
              <h6 class="h6 fw-bold">What is the backbone and what does it do?</h6>
              <p class="p">The backbone refers to the infrastructure that holds the initiative together and keeps the effort moving forward. Backbone support is made up of dedicated staff and administrative services to support the work.</p>
              <p class="p">It’s common for backbone services to be nested within an existing organization, especially for a startup effort. Identifying where those services will live is part of the inquiry you’ll start in the “gather support” step, and nail down in the “build core team and secure backbone support” step.</p>
              <!-- eslint-disable-next-line -->
              <label class="form-label mv-input-group__label" for="question8Textarea1">Where will the backbone live? Why?</label>
              <textarea
                v-model="question8Textarea[1]"
                class="form-control form-control-sm mv-input-group__input"
                id="question8Textarea1">
              </textarea>
            </div>
          </div>
          <div v-else-if="questionAnswers[8] === 'notyet' || questionAnswers[8] === 'no'">
            <div class="alert alert-light">
              <h6 class="h6 fw-bold">What is the backbone and what does it do?</h6>
              <p class="p">The backbone refers to the infrastructure that holds the initiative together and keeps the effort moving forward. Backbone support is made up of dedicated staff and administrative services to support the work.</p>
              <p class="p">It’s common for backbone services to be nested within an existing organization, especially for a startup effort. Identifying where those services will live is part of the inquiry you’ll start in the “gather support” step, and nail down in the “build core team and secure backbone support” step.</p>
              <!-- eslint-disable-next-line -->
              <label class="form-label mv-input-group__label" for="question8Textarea2">Which organization(s) might be a good fit to serve as the backbone?</label>
              <textarea
                v-model="question8Textarea[2]"
                class="form-control form-control-sm mv-input-group__input"
                id="question8Textarea2">
              </textarea>
            </div>
          </div>
        </div>
        <div v-else-if="currentQuestion === 9">
          <!-- Question 9 -->
          <h2 class="h3">We have secured the financial and staff resources to support the planning process and backbone infrastructure for at least one year, and made a five-year commitment to reducing youth homelessness.</h2>
          <form>
            <div class="row my-4">
              <div class="col">
                <label class="form-check-label" for="question9_yes">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="yes"
                    id="question9_yes"
                    v-model="questionAnswers[9]"
                    @click="answerQuestion({questionId: 9, answer: 'yes'});">
                  Yes
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question9_notyet">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="notyet"
                    id="question9_notyet"
                    v-model="questionAnswers[9]"
                    @click="answerQuestion({questionId: 9, answer: 'notyet'});">
                  Not yet, but we're working on it
                </label>
              </div>
              <div class="col">
                <label class="form-check-label" for="question9_no">
                  <input
                    class="form-check-input mv-checkbox__input"
                    type="radio"
                    value="no"
                    id="question9_no"
                    v-model="questionAnswers[9]"
                    @click="answerQuestion({questionId: 9, answer: 'no'});">
                  No
                </label>
              </div>
            </div>
          </form>
          <hr class="bg-default border-2 border-top border-default" aria-hidden="true">
          <div v-if="questionAnswers[9] === 'yes'">
            <div class="alert alert-light">
              <p class="p">Great! When you get to the “build core team and secure backbone support” step, you’ll use the Needs and Resources Worksheet to document those resources, and forecast future needs.</p>
            </div>
          </div>
          <div v-else-if="questionAnswers[9] === 'notyet' || questionAnswers[9] === 'no'">
            <div class="alert alert-light">
              <p class="p">When you get to the “build core team and secure backbone support” step, the Needs and Resources Worksheet will help you detail your initiative’s specific needs, and identify sources of support.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <hr class="bg-default border-2 border-top border-default" aria-hidden="true">
          <div class="col">
            <div class="question-nav d-none d-sm-block">
              <button
                v-show="currentQuestion > 1"
                class="btn btn-primary float-start"
                @click="setCurrentQuestion(currentQuestion - 1);">
                <i class="bi bi-chevron-left"></i> Previous Question
              </button>
              <button
                v-show="currentQuestion < 9"
                class="btn btn-primary float-end"
                @click="setCurrentQuestion(currentQuestion + 1);">
                Next Question <i class="bi bi-chevron-right"></i>
              </button>
            </div>
            <div class="question-nav d-block d-sm-none">
              <router-link
                tag="button"
                v-show="currentQuestion === 1"
                class="btn btn-primary btn-sm float-start"
                to="/">
                <i class="bi bi-chevron-left"></i> Home
              </router-link>
              <button
                v-show="currentQuestion > 1"
                class="btn btn-primary btn-sm float-start"
                @click="setCurrentQuestion(currentQuestion - 1);">
                <i class="bi bi-chevron-left"></i> Previous Question
              </button>
              <button
                v-show="currentQuestion < 9"
                class="btn btn-primary btn-sm float-end"
                @click="setCurrentQuestion(currentQuestion + 1);">
                Next Question <i class="bi bi-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2 d-none d-sm-block d-flex align-items-end">
        <nav id="question_nav" class="m-0 p-0">
          <ul class="list-unstyled">
            <li class="mv-btn-item"><a @click="setCurrentQuestion(1)" href="#question1" class="btn mv-sidenav-btn" :class="currentQuestion === 1 ? 'btn-primary active' : ' btn-info'"><span class="visually-hidden">Question 1</span>&nbsp;</a></li>
            <li class="mv-btn-item"><a @click="setCurrentQuestion(2)" href="#question2" class="btn mv-sidenav-btn" :class="currentQuestion === 2 ? 'btn-primary active' : ' btn-info'"><span class="visually-hidden">Question 2</span>&nbsp;</a></li>
            <li class="mv-btn-item"><a @click="setCurrentQuestion(3)" href="#question3" class="btn mv-sidenav-btn" :class="currentQuestion === 3 ? 'btn-primary active' : ' btn-info'"><span class="visually-hidden">Question 3</span>&nbsp;</a></li>
            <li class="mv-btn-item"><a @click="setCurrentQuestion(4)" href="#question4" class="btn mv-sidenav-btn" :class="currentQuestion === 4 ? 'btn-primary active' : ' btn-info'"><span class="visually-hidden">Question 4</span>&nbsp;</a></li>
            <li class="mv-btn-item"><a @click="setCurrentQuestion(5)" href="#question5" class="btn mv-sidenav-btn" :class="currentQuestion === 5 ? 'btn-primary active' : ' btn-info'"><span class="visually-hidden">Question 5</span>&nbsp;</a></li>
            <li class="mv-btn-item"><a @click="setCurrentQuestion(6)" href="#question6" class="btn mv-sidenav-btn" :class="currentQuestion === 6 ? 'btn-primary active' : ' btn-info'"><span class="visually-hidden">Question 6</span>&nbsp;</a></li>
            <li class="mv-btn-item"><a @click="setCurrentQuestion(7)" href="#question7" class="btn mv-sidenav-btn" :class="currentQuestion === 7 ? 'btn-primary active' : ' btn-info'"><span class="visually-hidden">Question 7</span>&nbsp;</a></li>
            <li class="mv-btn-item"><a @click="setCurrentQuestion(8)" href="#question8" class="btn mv-sidenav-btn" :class="currentQuestion === 8 ? 'btn-primary active' : ' btn-info'"><span class="visually-hidden">Question 8</span>&nbsp;</a></li>
            <li class="mv-btn-item"><a @click="setCurrentQuestion(9)" href="#question9" class="btn mv-sidenav-btn" :class="currentQuestion === 9 ? 'btn-primary active' : ' btn-info'"><span class="visually-hidden">Question 9</span>&nbsp;</a></li>
          </ul>
        </nav>
      </div>
      <div class="col-2 d-flex align-items-center d-sm-none">
        <nav id="question_nav" class="d-flex align-items-center justify-content-center m-0 p-0">
          <ul class="list-unstyled mv-btn-list d-flex flex-column">
            <li class="mv-btn-list__item">
              <a @click="setCurrentQuestion(1)" href="#question1" class="btn btn-sm mv-btn-list__link fw-bold" :class="currentQuestion === 1 ? 'btn-primary active' : ' btn-info'">Q1</a>
            </li>
            <li class="mv-btn-list__item">
              <a @click="setCurrentQuestion(2)" href="#question2" class="btn btn-sm mv-btn-list__link fw-bold" :class="currentQuestion === 2 ? 'btn-primary active' : ' btn-info'">Q2</a>
            </li>
            <li class="mv-btn-list__item">
              <a @click="setCurrentQuestion(3)" href="#question3" class="btn btn-sm mv-btn-list__link fw-bold" :class="currentQuestion === 3 ? 'btn-primary active' : ' btn-info'">Q3</a>
            </li>
            <li class="mv-btn-list__item">
              <a @click="setCurrentQuestion(4)" href="#question4" class="btn btn-sm mv-btn-list__link fw-bold" :class="currentQuestion === 4 ? 'btn-primary active' : ' btn-info'">Q4</a>
            </li>
            <li class="mv-btn-list__item">
              <a @click="setCurrentQuestion(5)" href="#question5" class="btn btn-sm mv-btn-list__link fw-bold" :class="currentQuestion === 5 ? 'btn-primary active' : ' btn-info'">Q5</a>
            </li>
            <li class="mv-btn-list__item">
              <a @click="setCurrentQuestion(6)" href="#question6" class="btn btn-sm mv-btn-list__link fw-bold" :class="currentQuestion === 6 ? 'btn-primary active' : ' btn-info'">Q6</a>
            </li>
            <li class="mv-btn-list__item">
              <a @click="setCurrentQuestion(7)" href="#question7" class="btn btn-sm mv-btn-list__link fw-bold" :class="currentQuestion === 7 ? 'btn-primary active' : ' btn-info'">Q7</a>
            </li>
            <li class="mv-btn-list__item">
              <a @click="setCurrentQuestion(8)" href="#question8" class="btn btn-sm mv-btn-list__link fw-bold" :class="currentQuestion === 8 ? 'btn-primary active' : ' btn-info'">Q8</a>
            </li>
            <li class="mv-btn-list__item">
              <a @click="setCurrentQuestion(9)" href="#question9" class="btn btn-sm mv-btn-list__link fw-bold" :class="currentQuestion === 9 ? 'btn-primary active' : ' btn-info'">Q9</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div class="container py-5">
    <div class="row">
      <div class="col">
        <router-link
          tag="button"
          class="btn btn-primary float-start"
          to="/">
          <i class="bi bi-chevron-left"></i>
        </router-link>
        <router-link
          tag="button"
          class="btn btn-primary float-end"
          to="/gather-support">
          <i class="bi bi-chevron-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import GetReadyPeopleList from '@/components/GetReadyPeopleList';
import MeasureSourceList from '@/components/MeasureSourceList';
export default {
  name: 'GetReadyPage',
  components: {
    GetReadyPeopleList,
    MeasureSourceList,
  },
  setup() {
    const store = useStore();
    return {
      currentQuestion: computed(() => store.state.readinessAssessment.currentQuestion),
      setCurrentQuestion: (questionId) => store.dispatch('readinessAssessment/setCurrentQuestion', { questionId }),
      questionAnswers: computed(() => store.state.readinessAssessment.questionAnswers),
      answerQuestion: ({ questionId, answer }) => store.dispatch('readinessAssessment/answerQuestion', { questionId, answer }),
      question2Textarea: computed({
        get: () => store.state.readinessAssessment.question2Textarea,
        set: (value) => store.dispatch('readinessAssessment/setQuestion2Textarea', { value }),
      }),
      question3Textarea: computed({
        get: () => store.state.readinessAssessment.question3Textarea,
        set: (value) => store.dispatch('readinessAssessment/setQuestion3Textarea', { value }),
      }),
      question5Textarea: computed({
        get: () => store.state.readinessAssessment.question5Textarea,
        set: (value) => store.dispatch('readinessAssessment/setQuestion5Textarea', { value }),
      }),
      question7Textarea: computed({
        get: () => store.state.readinessAssessment.question7Textarea,
        set: (value) => store.dispatch('readinessAssessment/setQuestion7Textarea', { value }),
      }),
      question8Textarea: computed({
        get: () => store.state.readinessAssessment.question8Textarea,
        set: (index, value) => store.dispatch('readinessAssessment/setQuestion8Textarea', { index, value }),
      }),
    };
  },
};
</script>
<style lang="scss" scoped>
// Alerts
.alert-light {
  background: transparent;
  border-color: transparent;
  box-shadow:none;
  color: $body-color;
  outline-color:transparent;
}

// Buttons
.btn-danger,
.btn-info,
.btn-secondary,
.btn-success {
  &,
  &:link,
  &:visited {
    color: $primary;
  }
}
.mv-sidenav-btn {
  width:36px;
}

// Lists
.mv-list {
  list-style-type: disc;
  &__item {
    margin: .5rem 0;
  }
}
.mv-btn-item {
  margin: 2px 0;
  padding:2px 10px;
}
.mv-btn-item a.active {
  font-weight: bold;
  letter-spacing:1px;
}
.mv-btn-list {
  display:flex;
  &__item {
    margin:2px;
    padding:0;
  }
  &__link {
    width:2.7rem;
  }
}

// Form Elements
.form-check-input[type="radio"] {
  background-image: none;
  border-color: $radio-input-border-color;
  border-radius: $radio-input-border-radius;
  border-width: $radio-input-border-width;
  box-shadow:none;
  margin-left:0;
  &:checked {
    background-image: $radio-input-checked-bg-img;
    box-shadow:none;
    outline:none;
  }
}
.mv-checkbox {
  align-items:center;
  display:flex;
  flex-direction:column;
  justify-content:center;
  text-align:center;
  &__label {
    color: $radio-input-label-text-color;
    font-size: $radio-input-label-font-size;
    font-weight: $input-group-label-font-weight;
    order:1;
  }
  &__input {
    margin-left:0;
    order:2;
  }
}
.mv-input-group {
  max-width: $input-group-max-width;
  width: $input-group-width;
  &__label {
    color: $input-group-label-text-color;
    font-size: $input-group-label-font-size;
    font-weight: $input-group-label-font-weight;
    order:1;
  }
  &__input {
    border-color:$input-group-input-border-color;
    border-radius: $input-group-input-border-radius;
    border-width: $input-group-input-border-width;
    color: $input-group-input-text-color;
    order:2;
    &:focus {
      border-color: $input-group-input-border-color-focus;
      box-shadow: none;
      outline: none;
    }
  }
}
// Images
.mv-illustration {
  &__img {
    height:auto;
    max-width: 992px;
    min-width: 576px;
  }
}
h3 {
  &.h3 {
    font-size: 18px;
  }
}
</style>
