<template>
  <div class="container-fluid mv-table-container mt-2 mb-4">
    <table class="table table-bordered mv-table">
      <thead class="mv-table__head">
      <tr class="mv-table__row">
        <th scope="col">Measure</th>
        <th scope="col">Data Source</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody class="mv-table__body" id="mv-table-content">

      <tr v-for="(entry, i) in records" :key="i"  class="mv-table__row">
        <td class="mv-table__cell">
          <!-- eslint-disable-next-line -->
          <label class="visually-hidden" :for="('proposedMeasure' + i)">Proposed Measure:</label>
          <input
            v-model="records[i].proposedMeasure"
            @blur="updateRecord(i, 'proposedMeasure', records[i].proposedMeasure);"
            placeholder="Proposed Measure..."
            class="mv-table__input"
            type="text"
            :id="('proposedMeasure' + i)"
            :name="('proposedMeasure' + i)">
        </td>
        <td class="mv-table__cell">
          <!-- eslint-disable-next-line -->
          <label class="visually-hidden" :for="('possibleDataSources' + i)">Possible Data Sources:</label>
          <input
            v-model="records[i].possibleDataSources"
            @blur="updateRecord(i, 'possibleDataSources', records[i].possibleDataSources);"
            placeholder="Possible Data Sources..."
            class="mv-table__input"
            type="text"
            :id="('possibleDataSources' + i)"
            :name="('possibleDataSources' + i)">
        </td>
        <td class="mv-table__cell d-flex align-items-center justify-content-end">
          <button class="btn btn-outline mv-icon-btn" aria-label="Delete Partner" @click="deleteRecord(i);">
            <i class="bi bi-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-end">
      <button class="btn btn-sm btn-primary" @click="addRecord()">+ Add Row</button>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'GetReadyPeopleList',
  props: {
    listIndex: {
      type: String,
      required: true,
      default: 'contextualIndicatorsList',
    },
  },
  setup(props) {
    const store = useStore();
    // eslint-disable-next-line
    const listIndex = props.listIndex;
    return {
      records: computed(() => {
        return store.state.measurement[listIndex];
      }),
      addRecord: () => {
        store.dispatch('measurement/addRecord', { type: listIndex });
      },
      deleteRecord: (id) => {
        const c = confirm('Are you sure you want to delete this record?');
        if (c === true) {
          store.dispatch('measurement/deleteRecord', { type: listIndex, id });
        }
      },
      updateRecord: (id, prop, value) => {
        store.dispatch('measurement/updateRecord', { type: listIndex, id, prop, value });
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.mv-table {
  border-color: transparent;
  font-size: $form-table-font-size;
  &__body {
    padding-top: 1rem;
  }
  &__head,
  thead {
    border-bottom: $form-table-border;
  }
  th {
    color: $form-table-label-text-color;
  }
  &__row {
    border-color: transparent;
  }
  &__cell,
  td {
    padding: $form-table-cell-padding;
  }
  &__input {
    background: $form-table-input-bg;
    border:2px solid $primary;
    border-radius:3px;
    color: $mv_secondary_blue;
    font-size:14px;
    height:100%;
    padding:4px 2px;
    transition: $transition-default;
    width: $form-table-input-width;
    &:focus,
    &:focus-visible {
      border-color: $info;
      outline:none;
    }
  }
}
.mv-icon-btn {
  padding: 1px 4px;
  i {
    font-size: 1.1rem;
  }
}
.btn-info,
.btn-secondary,
.btn-success,
.btn-danger {
  &,
  &:link,
  &:visited,
  &:active,
  &:hover {
    color: $light;
  }
}
</style>
